import React, { useState, useEffect } from "react";
import axios from "axios";

const AboutSection = () => {
  const API_URL = "https://kborder.onrender.com"; // Base URL for API requests

  const [categorizedImages, setCategorizedImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-images`);
        const images = response.data.data;
        categorizeImagesBySection(images);
      } catch (error) {
        console.error("Error fetching images:", error);
        setErrorMessage("Failed to load images. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const categorizeImagesBySection = (images) => {
    const categorized = images.reduce((acc, image) => {
      const { category } = image;
      if (!acc[category]) acc[category] = [];
      acc[category].push(image);
      return acc;
    }, {});

    setCategorizedImages(categorized);
  };

  return (
    <>
      <section className="section about" id="about">
        <div className="container">
          <figure className="about-banner">
            {loading ? (
              <p>Loading image...</p>
            ) : errorMessage ? (
              <p className="error-message">{errorMessage}</p>
            ) : categorizedImages["about-us"] &&
              categorizedImages["about-us"].length > 0 ? (
              <div className="image-item">
                <img
                  src={`${API_URL}/${
                    categorizedImages["about-us"][
                      categorizedImages["about-us"].length - 1
                    ].imageUrl
                  }`}
                  width="700"
                  height="auto"
                  loading="lazy"
                  alt="Kuch Bhi Order E-commerce - About Us"
                  className="w-100 banner-animation"
                />
              </div>
            ) : (
              <p>No image available for this section.</p>
            )}
          </figure>

          <div className="about-content">
            <h2 className="h2 section-title underline">About us</h2>

            <p className="about-text">
              Welcome to KuchBhiOrder.com, an easy-to-use platform that empowers
              entrepreneurs and businesses to build their own e-commerce stores
              effortlessly. Our mission is to simplify the process of setting up
              an online business by providing a comprehensive and user-friendly
              platform for creating, customizing, and managing e-commerce
              solutions. Whether you're a startup, a small business owner, or an
              established retailer, KuchBhiOrder.com offers the tools and
              flexibility needed to bring your online store to life.
            </p>
            <p className="about-text">
              We believe in the power of digital transformation and aim to make
              e-commerce accessible to everyone. Our platform is designed with
              features that cater to various business needs, from product
              management and payment integration to inventory management.
            </p>
            <p className="about-text">
              Join us on this journey to redefine online shopping and make your
              e-commerce vision a reality. At KuchBhiOrder.com, we do more than
              just enable product sales; we empower you to establish an online
              store effortlessly.
            </p>
          </div>
        </div>
      </section>
      <section className="section about">
        <div className="container">
          <div className="about-content">
            <h2 className="h2 section-title underline">Our Unique Value</h2>

            <p className="about-text">
              <strong>Quick and Easy Store Setup:</strong> Our platform allows
              you to set up your online store in just a few clicks. Simply add
              your products and inventory details, and you're ready to start
              selling.
            </p>
            <p className="about-text">
              <strong>Domain and Hosting Included:</strong> We offer domain and
              hosting services tailored to your needs, so you can launch your
              online store without any extra hassle.
            </p>
            <p className="about-text">
              <strong>Unlimited Product Listings:</strong> Easily add an
              unlimited number of products and create customized categories to
              organize your store effectively.
            </p>
            <p className="about-text">
              <strong>Live Notifications:</strong> Keep your customers engaged
              with instant notifications for new product launches, sales, and
              other important updates.
            </p>
            <p className="about-text">
              <strong>Go Global:</strong> Launch your store online and break
              geographical barriers, reaching customers across the globe.
            </p>
            <p className="about-text">
              <strong>Flexible Payment Options:</strong> Offer your customers a
              variety of secure payment methods to enhance their shopping
              experience and increase sales.
            </p>
            <p className="about-text">
              <strong>Exclusive Support for Premium Members:</strong> Our
              premium members receive additional assistance with inventory
              management, delivery models, digital marketing, and more to help
              grow their business.
            </p>
          </div>
          <figure className="about-banner">
            {loading ? (
              <p>Loading image...</p>
            ) : categorizedImages["unique-values"] &&
              categorizedImages["unique-values"].length > 0 ? (
              <img
                src={`https://kborder.onrender.com/${
                  categorizedImages["unique-values"][
                    categorizedImages["unique-values"].length - 1
                  ].imageUrl
                }`}
                width="700"
                height="auto"
                loading="lazy"
                alt="kuch Bhi Order E-commerce- Unique Values"
                className="w-100 banner-animation"
              />
            ) : (
              <p></p>
            )}
          </figure>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
