


// import React, { useState } from "react";
// import axios from "axios";

// const ModalBox = () => {
//   // State for form data
//   const [formData, setFormData] = useState({
//     fullName: "",
//     contact: "",
//     email: "",
//     businessName: "",
//     businessLocation: "",
//     businessType: "", // Dropdown
//     productCategory: "", // Dropdown
//     currentlySellOnline: false,
//     acceptablePaymentMethod: "",
//     productCatalog: "",
//     ecommerceProductUpdate: false,
//     doYouHaveDomain: false,
//     domainName: "",
//     alternativeDomainName: "",
//     domainExtension: "",
//     domainDuration: "",
//     domainBillingContactName: "",
//     domainEmailAddress: "",
//     domainContactNumber: "",
//     domainBillingAddress: "",
//     websiteLogo: "",
//     domainPaymentMethod: "",
//     gstNumber: "",
//     tandc: false, // Keep tandc in formData but remove from formFields
//   });

// //   // State for form step
//   const [step, setStep] = useState(1); // Initially, show step 1

// //   // State for messages
// //   const [message, setMessage] = useState("");
// //   const [error, setError] = useState("");

// //   // Handle form input changes
// //   const handleChange = (e) => {
// //     const { name, value, type, checked } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: type === "checkbox" ? checked : value,
// //     });
// //   };

// //   // Handle form submission
// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const response = await axios.post(
// //         "https://kborder.onrender.com/pricing-domain-details",
// //         formData
// //       );
// //       setMessage(response.data.message);
// //       setError("");
// //     } catch (err) {
// //       setError("Error saving details");
// //       setMessage("");
// //       console.error(err);
// //     }
// //   };


// const [message, setMessage] = useState('');
// const [error, setError] = useState('');

// // Load Razorpay Script
// const loadRazorpayScript = () => {
//     return new Promise((resolve) => {
//         const script = document.createElement('script');
//         script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//         script.onload = () => {
//             resolve(true);
//         };
//         script.onerror = () => {
//             resolve(false);
//         };
//         document.body.appendChild(script);
//     });
// };

// // Handle payment and form submission
// const handlePayment = async () => {
//     const scriptLoaded = await loadRazorpayScript();

//     if (!scriptLoaded) {
//         setError('Failed to load Razorpay SDK. Please check your network.');
//         return;
//     }

//     // Create order on backend
//     try {
//         const orderResponse = await axios.post('https://kborder.onrender.com/create-order', { amount: 2000 }); // 20 rs (2000 paise)
//         const { order } = orderResponse.data;

//         const options = {
//             key: 'rzp_test_BzMVkQvdeBvOhG', // Replace with your Razorpay key
//             amount: order.amount,
//             currency: 'INR',
//             name: 'John\'s Store',
//             description: 'Pricing Domain Payment',
//             order_id: order.id,
//             handler: async function (response) {
//                 // Payment successful, send form data and payment details to the backend
//                 const paymentData = {
//                     ...formData,
//                     amount: order.amount,
//                     razorpay_payment_id: response.razorpay_payment_id,
//                     razorpay_order_id: response.razorpay_order_id,
//                     razorpay_signature: response.razorpay_signature,
//                 };

//                 try {
//                     const res = await axios.post('https://kborder.onrender.com/pricing-domain-details', paymentData);
//                     setMessage(res.data.message);
//                     setError('');
//                     console.log("res",res)
//                     console.log("res",res)
//                 } catch (err) {
//                     setError('Error saving details');
//                     console.error(err);
//                 }
//             },
//             prefill: {
//                 name: formData.FullName,
//                 email: formData.Email,
//                 contact: formData.Contact,
//             },
//             theme: {
//                 color: '#F37254',
//             },
//         };

//         const paymentObject = new window.Razorpay(options);
//         paymentObject.open();

//     } catch (err) {
//         setError('Error creating Razorpay order');
//         console.error(err);
//     }
// };

// const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     setFormData({
//         ...formData,
//         [name]: type === 'checkbox' ? checked : value,
//     });
// };

// const handleSubmit = (e) => {
//     e.preventDefault();
//     handlePayment();
// };


//   // List of form fields (remove the "tandc" field from this list)
//   const formFields = [
//     { name: "fullName", type: "text", placeholder: "Full Name", required: true },
//     { name: "contact", type: "text", placeholder: "Contact Number", required: true },
//     { name: "email", type: "email", placeholder: "Email", required: true },
//     { name: "businessName", type: "text", placeholder: "Business Name", required: true },
//     { name: "businessLocation", type: "text", placeholder: "Business Location", required: true },
//     { 
//       name: "businessType", 
//       type: "select", 
//       options: ["Retail", "Wholesale", "Online Store","Manufacturer",,"Distributor","Service provider","Other"], 
//       placeholder: "Business Type", 
//       required: true 
//     },
//     { 
//       name: "productCategory", 
//       type: "select", 
//       options: ["Electronics", "Fashion & Apparel", "Home & Kitchen","Beauty & Personal Care","Groceries & Food Items","Furniture","Books & Stationery","Sports & Fitness","Automotive","Others"], 
//       placeholder: "Product Category", 
//       required: true 
//     },
//     { 
//       name: "currentlySellOnline", 
//       type: "select", 
//       options: ["Yes", "No"], 
//       placeholder: "Currently Sell Online", 
//       required: true 
//     },
//     { 
//       name: "acceptablePaymentMethod", 
//       type: "select", 
//       options: ["UPI", "Credit/Debit Card","COD","NetBanking"], 
//       placeholder: "Acceptable Payment Method", 
//       required: true 
//     },
//     // { name: "acceptablePaymentMethod", type: "text", placeholder: "Acceptable Payment Method" },
//     { name: "productCatalog", type: "text", placeholder: "Product Catalog" },
//     // { name: "ecommerceProductUpdate", type: "checkbox", label: "E-commerce Product Update" },
//     { 
//       name: "ecommerceProductUpdate", 
//       type: "select", 
//       options: ["Yes", "No"], 
//       placeholder: "E-commerce Product Update", 
//       required: true 
//     },
//     // { name: "doYouHaveDomain", type: "checkbox", label: "Do You Have a Domain?" },
//     { 
//       name: "doYouHaveDomain", 
//       type: "select", 
//       options: ["Yes", "No"], 
//       placeholder: "Do You Have a Domain?", 
//       required: true 
//     },
//     { name: "domainName", type: "text", placeholder: "Domain Name" },
//     { name: "alternativeDomainName", type: "text", placeholder: "Alternative Domain Name" },
//     // { name: "domainExtension", type: "text", placeholder: "Domain Extension (.com, .net)" },
//     { 
//       name: "domainExtension", 
//       type: "select", 
//       options: [".com", ".net"], 
//       placeholder: "Domain Extension (.com, .net)", 
//       required: true 
//     },
//     { name: "domainDuration", type: "text", placeholder: "Domain Duration" },
//     { 
//       name: "domainDuration", 
//       type: "select", 
//       options: ["1 Year", "2 Years", "3 Years","Other"], 
//       placeholder: "Domain Duration", 
//       required: true 
//     },
//     { name: "domainBillingContactName", type: "text", placeholder: "Domain Billing Contact Name" },
//     { name: "domainEmailAddress", type: "email", placeholder: "Domain Email Address" },
//     { name: "domainContactNumber", type: "text", placeholder: "Domain Contact Number" },
//     { name: "domainBillingAddress", type: "text", placeholder: "Domain Billing Address" },
//     { name: "websiteLogo", type: "text", placeholder: "Website Logo" },
//     // { name: "domainPaymentMethod", type: "text", placeholder: "Domain Payment Method" },
//     { 
//       name: "domainPaymentMethod", 
//       type: "select", 
//       options: ["UPI", "Credit/Debit Card","COD","NetBanking"], 
//       placeholder: "Domain Payment Method", 
//       required: true 
//     },
//     { 
//       name: "gstNumber", 
//       type: "select", 
//       options: ["Yes", "No"], 
//       placeholder: "Do You Have a GST Number?", 
//       required: true 
//     },
//   ];

//   const fieldsPerStep = 7; // How many fields per page/step
//   const totalSteps = Math.ceil(formFields.length / fieldsPerStep); // Total number of steps required

//   // Determine the fields to show based on the current step
//   const getCurrentFields = () => {
//     const startIndex = (step - 1) * fieldsPerStep;
//     const endIndex = startIndex + fieldsPerStep;
//     return formFields.slice(startIndex, endIndex);
//   };

//   // Functions to navigate between steps
//   const nextStep = () => setStep(step + 1);
//   const prevStep = () => setStep(step - 1);

//   return (
//     <div className="form-container">
//       <h2>Pricing Domain Details Form</h2>
//       <form onSubmit={handleSubmit}>
//         {/* Render fields dynamically based on the current step */}
//         <div className="form-step">
//           {getCurrentFields().map((field) => (
//             <div key={field.name}>
//               {field.type === "checkbox" ? (
//                 <label className="checkbox-label">
//                   {field.label}
//                   <input
//                     type="checkbox"
//                     name={field.name}
//                     checked={formData[field.name]}
//                     onChange={handleChange}
//                   />
//                 </label>
//               ) : field.type === "select" ? (
//                 <select
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleChange}
//                   required={field.required}
//                 >
//                   <option value="">{field.placeholder}</option>
//                   {field.options.map((option) => (
//                     <option key={option} value={option}>
//                       {option}
//                     </option>
//                   ))}
//                 </select>
//               ) : (
//                 <input
//                   type={field.type}
//                   name={field.name}
//                   value={formData[field.name]}
//                   onChange={handleChange}
//                   placeholder={field.placeholder}
//                   required={field.required}
//                 />
//               )}
//             </div>
//           ))}

//           {step === totalSteps && (
//             <div>
//               <label className="checkbox-label">
//               <input
//                   type="checkbox"
//                   name="tandc"
//                   checked={formData.tandc}
//                   onChange={handleChange}
//                   required
//                 />
//                 Accept Terms and Conditions
                
//               </label>
//             </div>
//           )}
//         </div>

//         <div className="btn-group">
//           {step > 1 && (
//             <button type="button" onClick={prevStep} className="btn back-btn">
//               ← Back
//             </button>
//           )}
//           {step < totalSteps && (
//             <button type="button" onClick={nextStep} className="btn next-btn">
//               Next →
//             </button>
//           )}

//           {step === totalSteps && (
//             <button type="submit" className="btn submit-btn">
//               Submit
//             </button>
//           )}
//         </div>

//         {/* Display success/error messages */}
//         {message && <p className="success-message">{message}</p>}
//         {error && <p className="error-message">{error}</p>}
//       </form>
//     </div>
//   );
// };

// export default ModalBox;




import React, { useState } from "react";
import axios from "axios";

const ModalBox = () => {
  // State for form data
  const [formData, setFormData] = useState({
    fullName: "",
    contact: "",
    email: "",
    businessName: "",
    businessLocation: "",
    businessType: "", // Dropdown
    productCategory: "", // Dropdown
    currentlySellOnline: false,
    acceptablePaymentMethod: "",
    productCatalog: "",
    ecommerceProductUpdate: false,
    doYouHaveDomain: false,
    domainName: "",
    alternativeDomainName: "",
    domainExtension: "",
    domainDuration: "",
    domainBillingContactName: "",
    domainEmailAddress: "",
    domainContactNumber: "",
    domainBillingAddress: "",
    websiteLogo: "",
    domainPaymentMethod: "",
    gstNumber: "",
    tandc: false, // Keep tandc in formData but remove from formFields
  });

  // State for form step
  const [step, setStep] = useState(1);

  // State for messages
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Load Razorpay Script
  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  // Handle payment and form submission
  const handlePayment = async () => {
    const scriptLoaded = await loadRazorpayScript();

    if (!scriptLoaded) {
      setError("Failed to load Razorpay SDK. Please check your network.");
      return;
    }

    // Create order on backend
    try {
      const orderResponse = await axios.post("https://kborder.onrender.com/create-order", { amount: 2000 }); // Amount in paise (2000 = 20 INR)
      const { order } = orderResponse.data;

      const options = {
        key: "rzp_test_BzMVkQvdeBvOhG", // Replace with your Razorpay key
        amount: order.amount,
        currency: "INR",
        name: "KuchBhiOrder.com",
        description: "Pricing Domain Payment",
        order_id: order.id,
        handler: async function (response) {
          // Payment successful, send form data and payment details to the backend
          const paymentData = {
            ...formData,
            amount: order.amount,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const res = await axios.post("https://kborder.onrender.com/pricing-domain-details", paymentData);
            setMessage(res.data.message);
            setError("");
            console.log("Response:", res.data);
          } catch (err) {
            setError("Error saving details");
            console.error(err);
          }
        },
        prefill: {
          name: formData.fullName,
          email: formData.email,
          contact: formData.contact,
        },
        theme: {
          color: "#F37254",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (err) {
      setError("Error creating Razorpay order");
      console.error(err);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
    });
};

  // const handleChange = (e) => {
  //   const { name, value, type, checked } = e.target;

  //   if (type === "select") {
  //     // Convert "Yes" to true and "No" to false for boolean fields
  //     if (name === "currentlySellOnline" || name === "ecommerceProductUpdate" || name === "doYouHaveDomain") {
  //       setFormData({
  //         ...formData,
  //         [name]: value === "Yes", // Convert to boolean
  //       });
  //     } else {
  //       setFormData({
  //         ...formData,
  //         [name]: value,
  //       });
  //     }
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [name]: type === "checkbox" ? checked : value,
  //     });
  //   }
  // };

  const handleSubmit = (e) => {
     // Validations
     if (!isValidEmail(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }

    if (!isValidContact(formData.contact)) {
      setError("Contact number must be a 10-digit number.");
      return;
    }
    e.preventDefault();
    handlePayment(); // Handle payment and form submission
  };

  // List of form fields
  const formFields = [
    { name: "fullName", type: "text", placeholder: "Full Name", required: true },
    { name: "contact", type: "text", placeholder: "Contact Number", required: true },
    { name: "email", type: "email", placeholder: "Email", required: true },
    { name: "businessName", type: "text", placeholder: "Business Name", required: true },
    { name: "businessLocation", type: "text", placeholder: "Business Location", required: true },
    { name: "businessType", type: "select", options: ["Retail", "Wholesale", "Online Store", "Manufacturer", "Distributor", "Service provider", "Other"], placeholder: "Business Type", required: true },
    { name: "productCategory", type: "select", options: ["Electronics", "Fashion & Apparel", "Home & Kitchen", "Beauty & Personal Care", "Groceries & Food Items", "Furniture", "Books & Stationery", "Sports & Fitness", "Automotive", "Others"], placeholder: "Product Category", required: true },
    { name: "currentlySellOnline", type: "select", options: [ "No","Yes"], placeholder: "Currently Sell Online", required: true },
    { name: "acceptablePaymentMethod", type: "select", options: ["UPI", "Credit/Debit Card", "COD", "NetBanking"], placeholder: "Acceptable Payment Method", required: true },
    { name: "productCatalog", type: "select", options: ["No","Yes"], placeholder: "Do You Have a Product Catalog" },
    { name: "ecommerceProductUpdate", type: "select", options: ["No","Yes"], placeholder: "E-commerce Product Update" },
    { name: "doYouHaveDomain", type: "select", options: [ "No","Yes"], placeholder: "Do You Have a Domain?", required:true},
    { name: "domainName", type: "text", placeholder: "Domain Name" },
    { name: "alternativeDomainName", type: "text", placeholder: "Alternative Domain Name" },
    { name: "domainExtension", type: "select", options: [".com", ".net"], placeholder: "Domain Extension (.com, .net)", required: true },
    { name: "domainDuration", type: "select", options: ["1 Year", "2 Years", "3 Years", "Other"], placeholder: "Domain Duration", required: true },
    { name: "domainBillingContactName", type: "text", placeholder: "Domain Billing Contact Name" },
    { name: "domainEmailAddress", type: "email", placeholder: "Domain Email Address" },
    { name: "domainContactNumber", type: "text", placeholder: "Domain Contact Number" },
    { name: "domainBillingAddress", type: "text", placeholder: "Domain Billing Address" },
    { name: "websiteLogo", type: "text", placeholder: "Website Logo" },
    { name: "domainPaymentMethod", type: "select", options: ["UPI", "Credit/Debit Card", "COD", "NetBanking"], placeholder: "Domain Payment Method", required: true },
    { name: "gstNumber", type: "select", options: [ "No","Yes"], placeholder: "Do You Have a GST Number?", required: true },
    { name: "tandc", type: "checkBox",  placeholder: "Agree terms and condition", required: true },
  ];

  const fieldsPerStep = 5; // Number of fields per step
  const totalSteps = Math.ceil(formFields.length / fieldsPerStep);

  // Validate email format
  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Validate contact number (Assuming a 10-digit phone number)
  const isValidContact = (contact) => {
    const regex = /^\d{10}$/;
    return regex.test(contact);
  };

  const nextStep = () => {
    if (step < totalSteps) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <div className="modal-box">
      <form onSubmit={handleSubmit}>
        <div className="form-fields">
          {formFields.slice((step - 1) * fieldsPerStep, step * fieldsPerStep).map((field, index) => (
            <div key={index} className="form-field">
              <label>{field.placeholder}</label>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                >
                  <option value="" disabled>Select {field.placeholder}</option>
                  {field.options.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={field.type}
                  name={field.name}
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                  onChange={handleChange}
                  required={field.required}
                />
              )}
              
            </div>
          ))}
        
        </div>

        {/* Navigation buttons */}
        <div className="btn-group">
          {step > 1 && <button type="button" onClick={prevStep} className="btn back-btn">← Back</button>}
          {step < totalSteps ? (
 <button type="button" onClick={nextStep} className="btn next-btn"> Next →</button>          ) : (
<button type="submit" className="btn submit-btn">Submit</button>          )}
        </div>
      </form>

      {/* Display messages */}
      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ModalBox;
