import React, { useState, useEffect } from "react";
import RequestQuote from "./RequestQuote";
import StepsSlider from "./StepsSlider";
import axios from "axios";
import video from "./assets/KuchBhiOrder.com.mp4";
import ownership from "./assets/ownership.gif";
import Search from "./Search";
import FAQSection from "./FAQs";
import HeroSection from "./HeroSection";
import AboutSection from "./AboutSection";
import PricingSection from "./PricingSection";
import FeaturesSection from "./FeaturesSection";
function LandingPage() {
  // const [showModal, setShowModal] = useState(false);

  // const handleModalShow = () => setShowModal(true);
  // const handleModalClose = () => setShowModal(false);

  const [mediaFiles, setMediaFiles] = useState([]);
  const [, setCurrentIndex] = useState(0); // Manage current index
  const [isPlayingVideo, setIsPlayingVideo] = useState(false); // Track if video is playing
  // const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [, setCurrentLetterIndex] = useState(0);
  // const [displayedText, setDisplayedText] = useState("");
  const [, setgetHomeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setMessage] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(""); // Default logo path or initial logo

  // const words = ["CREATE", "CUSTOMIZE", "LAUNCH"];
  // const baseText = "Your Online Store with KuchBhiOrder.com";
  const API_URL = "https://kborder.onrender.com"; // Backend URL

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentLetterIndex((prevIndex) => {
  //       // If current word is fully displayed, move to the next word
  //       if (prevIndex < words[currentWordIndex].length - 1) {
  //         return prevIndex + 1;
  //       } else {
  //         // Reset for the next word
  //         setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
  //         return 0; // Reset to the first letter of the new word
  //       }
  //     });
  //   }, 300); // Change letter every 300ms

  //   return () => clearInterval(interval);
  // }, [currentWordIndex, words]);

  // Fetch the current logo when the component mounts
  useEffect(() => {
    const fetchCurrentLogo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/current-logo`);
        console.log("Fetched logo:", response.data.logo); // Log the logo path
        if (response.data.success && response.data.logo) {
          setCurrentLogo(response.data.logo); // Set fetched logo
        }
      } catch (error) {
        console.error("Error fetching current logo:", error);
      }
    };

    fetchCurrentLogo();
  }, []);

  // Fetch media files from the server
  useEffect(() => {
    const fetchMediaFiles = async () => {
      try {
        const response = await axios.get(
          "https://besto-ecommrce.onrender.com/media"
        );
        setMediaFiles(response.data);
      } catch (error) {
        console.error("Error fetching media files:", error);
      }
    };
    fetchMediaFiles();
  }, []);

  // Handle video start and end events
  const handleVideoStarted = () => {
    setIsPlayingVideo(true); // Pause the carousel auto-slide when video starts
  };

  const handleVideoEnded = () => {
    setIsPlayingVideo(false); // Resume auto-slide after video ends
    setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length); // Move to the next slide
  };

  // Automatically slide images when not playing video
  useEffect(() => {
    if (!isPlayingVideo) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % mediaFiles.length); // Cycle through all media
      }, 3000); // Change every 3 seconds

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [isPlayingVideo, mediaFiles.length]);

  useEffect(() => {
    // Function to fetch home section content
    const fetchHomeSection = async () => {
      try {
        const response = await axios.get(
          "https://kborder.onrender.com/api/get-home-section"
        ); // Adjust the URL as needed
        console.log("Data:", response.data);
        // Set the home section data directly from the response data
        setgetHomeData(response.data.data); // Accessing the array from response.data.data
        console.log("Home Section Data:", response.data.data);
      } catch (error) {
        console.error("Error fetching home section data:", error);
      }
    };
    // Call the function
    fetchHomeSection();
  }, []);

  const [categorizedImages, setCategorizedImages] = useState({});

  const fetchImages = async () => {
    try {
      const response = await axios.get("https://kborder.onrender.com/api/get-images");
      const images = response.data.data;
      categorizeImagesBySection(images);
    } catch (error) {
      console.error("Error fetching images:", error);
      setMessage({ type: "error", text: "Failed to load images" });
    } finally {
      setLoading(false);
    }
  };

  const categorizeImagesBySection = (images) => {
    const categorized = images.reduce((acc, image) => {
      const { category } = image;
      if (!acc[category]) {
        acc[category] = []; // Initialize as an array
      }
      acc[category].push(image); // Push each image object into the respective category
      return acc;
    }, {});

    setCategorizedImages(categorized);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  // const [query, setQuery] = useState("");
  const [websiteData, setWebsiteData] = useState([]);
  // const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Fetch the website data from the backend on mount
    axios
      .get("https://kborder.onrender.com/api/get-website-data")
      .then((response) => {
        setWebsiteData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching website data:", error);
      });
  }, []);

  return (
    <div>
      {/* #HEADER */}

      <header className="header" data-header>
        <div className="container">
          <div className="overlay" data-overlay></div>

          <a href="#home">
            <img
              src={`${API_URL}${currentLogo}`} // Fallback to default logo
              alt="Kuch Bhi Order-Logo"
            />
          </a>

          <nav className="navbar" data-navbar>
            <div className="navbar-top">
              <a href="#home" className="logo">
                Ted Martins
              </a>

              <button
                className="nav-close-btn"
                aria-label="Close Menu"
                data-nav-close-btn
              >
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>

            <ul className="navbar-list">
              {/* <li className="navbar-item">
                                <a href="#home" className="navbar-link" data-navbar-link>Home</a>
                            </li> */}
              <li className="navbar-item">
                <a href="#store" className="navbar-link" data-navbar-link>
                  Search Store
                </a>
              </li>

              <li className="navbar-item">
                <a href="#about" className="navbar-link" data-navbar-link>
                  About
                </a>
              </li>

              <li className="navbar-item">
                <a href="#Resources" className="navbar-link" data-navbar-link>
                  Resources
                </a>
              </li>

              <li className="navbar-item">
                <a href="#Pricing" className="navbar-link" data-navbar-link>
                  Pricing
                </a>
              </li>

              <li className="navbar-item">
                <a href="#features" className="navbar-link" data-navbar-link>
                  Features
                </a>
              </li>

              <li className="navbar-item">
                <a href="#FAQ's" className="navbar-link" data-navbar-link>
                  FAQ's
                </a>
              </li>
            </ul>
          </nav>

          <a href="#quoteRequest" className="btn">
            <ion-icon
              name="chevron-forward-outline"
              aria-hidden="true"
            ></ion-icon>

            <span>Get A Quote</span>
          </a>
        </div>
      </header>

      <main>
        <article>
          {/* #HERO */}
          <HeroSection />
          {/* toggle store content display on search button */}
          <div
            className="card-container"
            style={{ flexWrap: "wrap", gap: "10px", marginTop: "20px" }}
            id="store"
          >
            <Search websiteData={websiteData} />
          </div>
          {/* #ABOUT */}
          {/* why choose us */}
          <AboutSection />

          {/* #Pricing */}
          <PricingSection />
          {/* #features */}
          <FeaturesSection />

          <div className="container dataownership" id="resources">
            <div className="datarow">
              <div className="col-md-6">
                <h2 className="h2 section-title underline">
                  Your store and data belong to you, Always
                </h2>
                <p className="text">
                  Including control over where your data is stored and how it
                  can be used.
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src={ownership}
                  width="750"
                  height="300"
                  loading="lazy"
                  alt="Data Security"
                  className="img-cover"
                />
              </div>
            </div>
          </div>

          <section className="section blog" id="Resources">
            <StepsSlider />
            <video
              controls
              autoPlay
              muted
              onPlay={handleVideoStarted} // Pause auto-slide on video play
              onEnded={handleVideoEnded} // Move to next slide when video ends
              width="100%"
              alt="Video on how to setup an e-commerce store and its features"
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </section>

          {/* get request quote */}

          <section className="quoteRequest" id="quoteRequest">
            <div className="quotes">
              <figure className="about-banner">
                {loading ? (
                  <p>Loading images...</p>
                ) : categorizedImages["quote"] &&
                  categorizedImages["quote"].length > 0 ? (
                  <img
                    src={`https://kborder.onrender.com/${
                      categorizedImages["quote"][
                        categorizedImages["quote"].length - 1
                      ].imageUrl
                    }`} // Access the most recent image of the 'quote' category
                    width="700"
                    height="532"
                    loading="lazy"
                    alt="Quote banner"
                    className="w-100 banner-animation"
                  />
                ) : (
                  <p></p>
                )}
              </figure>

              <div className="request-quote-content">
                <h2 className="h2 section-title underline">Request A Quote</h2>
                <RequestQuote />
              </div>
            </div>
          </section>
          <section className="FAQ's" id="FAQ's">
            <FAQSection />
          </section>
        </article>
      </main>

      {/* #FOOTER */}

      {/* #GO TO TOP */}

      <a
        href="#top"
        className="go-top active"
        aria-label="Go To Top"
        data-go-top
      >
        {/* <ion-icon name="arrow-up-outline"></ion-icon> */}↑
      </a>
    </div>
  );
}

export default LandingPage;
