import React, { useState } from "react";
import "./FAQSection.css";

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the KuchBhiOrder.com?",
      answer:
        "KuchBhiOrder.com is an e-commerce platform that allows you to create and manage your online store effortlessly, offering features like customizable designs, product management, and secure payment options.",
    },
    {
      question: "How do I get started with my online store?",
      answer:
        "To start your online store with Kuch Bhi Order, simply sign up for an account, choose a domain name, customize your store design, add products, and set up your payment options.",
    },
    {
      question: "Is there a limit to the number of products I can add?",
      answer:
        "No, there is no limit! You can add an unlimited number of products with Kuch Bhi Order and create customized categories to organize your offerings.",
    },
    {
      question: "What payment options does KuchBhiOrder.com support?",
      answer:
        "We support various secure payment methods, including credit/debit cards, UPI, Cash on Delivery, and bank transfers, providing flexibility for your customers.",
    },
    {
      question: "Can I use gift cards in my store?",
      answer:
        "Yes! You can offer redeemable gift cards as discounts for customers on their next purchase, enhancing customer engagement and loyalty.",
    },
    {
      question: "Is my online store mobile-friendly?",
      answer:
        "Absolutely! Our platform ensures that your online store is mobile responsive, providing an optimal shopping experience on all devices.",
    },
    {
      question: "How can I track my sales and customer behavior?",
      answer:
        "Kuch Bhi Order offers analytics and reporting features that give you insights into your store’s performance, sales trends, and customer behavior.",
    },
    {
      question: "What kind of support does the KuchBhiOrder.com provide?",
      answer:
        "We offer 24/7 customer support to assist you with any questions or issues you may encounter while using our platform.",
    },
    {
      question: "Can I customize the design of my online store?",
      answer:
        "Yes, our platform provides a variety of customizable store and layout options, allowing you to personalize your store to match your brand's identity.",
    },
    {
      question: "Are there any hidden fees?",
      answer:
        "No, we believe in transparency. Our pricing plans are straightforward with no hidden fees, ensuring you know exactly what you’re paying for.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs-container">
      <h2 className="faqs-title">Frequently Asked Questions</h2>
      <div className="faqs-list">
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${activeIndex === index ? "expanded" : ""}`}
            key={index}
          >
            <div className="faq-header" onClick={() => handleToggle(index)}>
              <span className="arrow-icon">
                {activeIndex === index ? "▼" : "▶"}
              </span>
              <p className="faq-question">{faq.question}</p>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
