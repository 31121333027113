
import React, { useState } from 'react';
import axios from 'axios';

const PricingForm = () => {
    const [formData, setFormData] = useState({
        FullName: '',
        Contact: '',
        Email: '',
        BusinessName: '',
        BusinessLocation: '',
        BusinessType: '',
        productCategory: '',
        CurrentlySellOnline: false,
        AcceptablePaymentMethod: '',
        ProductCatalog: '',
        EcommerceProductUpdate: false,
        DoYouHaveDomain: false,
        DomainName: '',
        AlernativeDomainName: '',
        DomainExtension: '',
        DomainDuration: '',
        DomainBillingContactName: '',
        DomainEmailAddress: '',
        DomainContactNumber: '',
        DomainBillingAddress: '',
        WebsiteLogo: '',
        DomainPaymentMethod: '',
        GSTNumber: '',
        tandc: false,
    });

    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Load Razorpay Script
    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    // Handle payment and form submission
    const handlePayment = async () => {
        const scriptLoaded = await loadRazorpayScript();

        if (!scriptLoaded) {
            setError('Failed to load Razorpay SDK. Please check your network.');
            return;
        }

        // Create order on backend
        try {
            const orderResponse = await axios.post('https://kborder.onrender.com/create-order', { amount: 2000 }); // 20 rs (2000 paise)
            const { order } = orderResponse.data;

            const options = {
                key: 'rzp_test_BzMVkQvdeBvOhG', // Replace with your Razorpay key
                amount: order.amount,
                currency: 'INR',
                name: 'John\'s Store',
                description: 'Pricing Domain Payment',
                order_id: order.id,
                handler: async function (response) {
                    // Payment successful, send form data and payment details to the backend
                    const paymentData = {
                        ...formData,
                        amount: order.amount,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature,
                    };

                    try {
                        const res = await axios.post('https://kborder.onrender.com/pricing-domain-details', paymentData);
                        setMessage(res.data.message);
                        setError('');
                        console.log("res",res)
                    } catch (err) {
                        setError('Error saving details');
                        console.error(err);
                    }
                },
                prefill: {
                    name: formData.FullName,
                    email: formData.Email,
                    contact: formData.Contact,
                },
                theme: {
                    color: '#F37254',
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();

        } catch (err) {
            setError('Error creating Razorpay order');
            console.error(err);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handlePayment();
    };

    return (
        <div className="container">
            <h2>Pricing Domain Details Form</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="FullName"
                    value={formData.FullName}
                    onChange={handleChange}
                    placeholder="Full Name"
                    required
                />
                <input
                    type="text"
                    name="Contact"
                    value={formData.Contact}
                    onChange={handleChange}
                    placeholder="Contact Number"
                    required
                />
                <input
                    type="email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                />
                <input
                    type="text"
                    name="BusinessName"
                    value={formData.BusinessName}
                    onChange={handleChange}
                    placeholder="Business Name"
                    required
                />
                <input
                    type="text"
                    name="BusinessLocation"
                    value={formData.BusinessLocation}
                    onChange={handleChange}
                    placeholder="Business Location"
                    required
                />
                <input
                    type="text"
                    name="BusinessType"
                    value={formData.BusinessType}
                    onChange={handleChange}
                    placeholder="Business Type"
                    required
                />
                <input
                    type="text"
                    name="productCategory"
                    value={formData.productCategory}
                    onChange={handleChange}
                    placeholder="Product Category"
                    required
                />
                <label>
                    <input
                        type="checkbox"
                        name="CurrentlySellOnline"
                        checked={formData.CurrentlySellOnline}
                        onChange={handleChange}
                    />{' '}
                    Currently Sell Online
                </label>
                <input
                    type="text"
                    name="AcceptablePaymentMethod"
                    value={formData.AcceptablePaymentMethod}
                    onChange={handleChange}
                    placeholder="Payment Method"
                    required
                />
                <input
                    type="text"
                    name="ProductCatalog"
                    value={formData.ProductCatalog}
                    onChange={handleChange}
                    placeholder="Product Catalog"
                    required
                />
                <label>
                    <input
                        type="checkbox"
                        name="EcommerceProductUpdate"
                        checked={formData.EcommerceProductUpdate}
                        onChange={handleChange}
                    />{' '}
                    Ecommerce Product Update
                </label>
                <label>
                    <input
                        type="checkbox"
                        name="DoYouHaveDomain"
                        checked={formData.DoYouHaveDomain}
                        onChange={handleChange}
                    />{' '}
                    Do You Have a Domain?
                </label>
                <input
                    type="text"
                    name="DomainName"
                    value={formData.DomainName}
                    onChange={handleChange}
                    placeholder="Domain Name"
                />
                <input
                    type="text"
                    name="AlernativeDomainName"
                    value={formData.AlernativeDomainName}
                    onChange={handleChange}
                    placeholder="Alternative Domain Name"
                />
                <input
                    type="text"
                    name="DomainExtension"
                    value={formData.DomainExtension}
                    onChange={handleChange}
                    placeholder="Domain Extension (.com, .net)"
                />
                <input
                    type="text"
                    name="DomainDuration"
                    value={formData.DomainDuration}
                    onChange={handleChange}
                    placeholder="Domain Duration"
                />
                <input
                    type="text"
                    name="DomainBillingContactName"
                    value={formData.DomainBillingContactName}
                    onChange={handleChange}
                    placeholder="Billing Contact Name"
                />
                <input
                    type="email"
                    name="DomainEmailAddress"
                    value={formData.DomainEmailAddress}
                    onChange={handleChange}
                    placeholder="Billing Email Address"
                />
                <input
                    type="text"
                    name="DomainContactNumber"
                    value={formData.DomainContactNumber}
                    onChange={handleChange}
                    placeholder="Billing Contact Number"
                />
                <input
                    type="text"
                    name="DomainBillingAddress"
                    value={formData.DomainBillingAddress}
                    onChange={handleChange}
                    placeholder="Billing Address"
                />
                <input
                    type="text"
                    name="WebsiteLogo"
                    value={formData.WebsiteLogo}
                    onChange={handleChange}
                    placeholder="Website Logo URL"
                />
                <input
                    type="text"
                    name="DomainPaymentMethod"
                    value={formData.DomainPaymentMethod}
                    onChange={handleChange}
                    placeholder="Domain Payment Method"
                />
                <input
                    type="text"
                    name="GSTNumber"
                    value={formData.GSTNumber}
                    onChange={handleChange}
                    placeholder="GST Number"
                />
                <label>
                    <input
                        type="checkbox"
                        name="tandc"
                        checked={formData.tandc}
                        onChange={handleChange}
                    />{' '}
                    Accept Terms and Conditions
                </label>

                <button type="submit">Submit and Pay</button>
            </form>

            {message && <p className="success">{message}</p>}
            {error && <p className="error">{error}</p>}
        </div>
    );
};

export default PricingForm;
