import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import ModalBox from "./ModalBox";

const PricingSection = () => {
  const [showModal, setShowModal] = useState(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const API_URL = "https://kborder.onrender.com"; // Base URL for API requests

  const [categorizedImages, setCategorizedImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-images`);
        const images = response.data.data;
        categorizeImagesBySection(images);
      } catch (error) {
        console.error("Error fetching images:", error);
        setErrorMessage("Failed to load images. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const categorizeImagesBySection = (images) => {
    const categorized = images.reduce((acc, image) => {
      const { category } = image;
      if (!acc[category]) acc[category] = [];
      acc[category].push(image);
      return acc;
    }, {});

    setCategorizedImages(categorized);
  };

  return (
    <section className="section features" id="Pricing">
      <h2 className="h2 section-title underline">Our Pricing</h2>
      <div className="container">
        <ul className="features-list">
          <li>
            <div className="features-card">
              <div className="icon">
                <ion-icon name="bulb-outline"></ion-icon>
              </div>

              <div className="content" onClick={handleModalShow}>
                <h3 className="h3 title">Standard Plan</h3>

                <h3 className="h3 title">Price: ₹ 20 / Month</h3>
                <p className="text">
                  <ul class="google-bullets">
                    <li>24x7 Live Phone and Chat Support</li>
                    <li>Unlimited Products and Users</li>
                    <li>Abandoned Cart Emails</li>
                    <li>Advanced Product Filter</li>
                    <li>Flexible Payment Options</li>
                    <li>Store Pick Up Facility</li>
                    <li>Detailed Dashboard</li>
                  </ul>
                </p>
                <br />
                <button className="btn" aria-label="Show More">
                  Register Now
                </button>
              </div>
            </div>
          </li>
        </ul>

        <figure className="features-banner">
          {loading ? (
            <p>Loading image...</p>
          ) : categorizedImages["pricing"] &&
            categorizedImages["pricing"].length > 0 ? (
            <img
              src={`https://kborder.onrender.com/${
                categorizedImages["pricing"][
                  categorizedImages["pricing"].length - 1
                ].imageUrl
              }`}
              width="369"
              height="auto"
              loading="lazy"
              alt="Kuch Bhi Order E-commerce- Pricing"
              className="w-100 banner-animation"
            />
          ) : (
            <p></p>
          )}
        </figure>
        <ul className="features-list">
          <li>
            <div className="features-card">
              <div className="icon">
                <ion-icon name="bulb-outline"></ion-icon>
              </div>

              <div className="content">
                <h3 className="h3 title">Custom Plan</h3>

                <h3 className="h3 title">Price: Get A Quote</h3>
                <p className="text">
                  <ul class="google-bullets">
                    <li>
                      Fully customizable features and services to match your
                      business requirements
                    </li>
                    <li>Personalized support and consulting</li>
                    <li>Flexible integrations and tools</li>
                    <li>Scalable solutions for large enterprises</li>
                    <li>
                      Any additional features you need to achieve your business
                      goals
                    </li>
                  </ul>
                </p>
                <br />
                <a href="#quoteRequest" className="btn">
                  Get A Quote
                </a>
              </div>
            </div>
          </li>
        </ul>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal">
              <div className="modal-header">
                <h3>Fill the Form</h3>
                <button className="close-button" onClick={handleModalClose}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <ModalBox />
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PricingSection;
