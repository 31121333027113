
import React from 'react'
import './PrivacyPolicy.css'
import { Link,useNavigate } from 'react-router-dom'
import Footer from './Footer';

function Return() {
    const currentYear = new Date().getFullYear()
    const navigate = useNavigate();

    const goToHomePage = () => {
      navigate('/');  // Navigate to the home page (root URL)
    };
    return (
        <div>
            {/*header section*/}
            <main>
                <article>
                    {/* #HERO */}


                    <section className="hero" id="home">
                        <div className="container">
                            <div className="hero-content">
                                {/* <p  className="hero-subtitle">We Are Product Designer From UK</p > */}
                                {/* <p  className="hero-subtitle">How Kuchbhiorder handles your data</p > */}
                                {/* <h2 className="h2 hero-title">We Design Interfaces That Users Love</h2> */}
                                <h2 className="h2 hero-title">Refund and Cancellation Policy for KuchBhiOrder.com
                                </h2>

                                {/* <p  className="hero-text">
                                        Morbi sed lacus nec risus finibus feugiat et fermentum nibh.
                                        Pellentesque vitae ante at elit fringilla ac at purus.
                                    </p > */}
      <button className="btn" onClick={goToHomePage}>Back To Home</button>
      </div>

                            <figure className="hero-banner">
                                <img
                                    src="https://raw.githubusercontent.com/codewithsadee/desinic/master/assets/images/hero-banner.png"
                                    width="694"
                                    height="529"
                                    loading="lazy"
                                    alt="hero-banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>
                        </div>
                    </section>

               
<div>
    <section className='section privacy'>
        <h2>Refund and Cancellation Policy for KuchBhiOrder.com </h2>
<p className="about-text">
Effective Date: 23/ 10/ 2024
</p ><p className="about-text">
At KuchBhiOrder.com, we are dedicated to empowering our users to create and manage their e-commerce stores effectively. We understand that sometimes plans change, and we want to ensure you are informed about our refund and cancellation policy.
</p ><p className="about-text">
1. Cancellation Policy
- Subscription Cancellation: Users may cancel their subscription plan at any time before the next billing cycle. To cancel your subscription, please contact our support team 
- Cancellation Charges: Please note that cancellation charges are applicable and vary depending on your chosen plan. 
- Refund on Cancellation: Cancellations made before the renewal date will not incur any charges for the upcoming billing period, aside from applicable cancellation charges.
</p ><p className="about-text">
2. Refund Policy
- Eligibility for Refunds: Users are eligible for a refund if they cancel their subscription within 7 days of the initial purchase. After this period, no refunds will be granted.
- Non-Refundable Services: Fees for custom services, one-time setup, or any add-on features are non-refundable.
- Refund Processing Time: Approved refunds will be processed within 14 days back to the original payment method.
</p ><p className="about-text">
3. Exchanges
- Since this platform involves subscription services and digital products, exchanges are not applicable.
</p ><p className="about-text">
4. Support and Assistance
If you have any questions regarding your subscription, cancellation, or refund, please reach out to our support team:
- Email: contact@kuchbhiorder.com
- Phone: +91 9423529427 / 9272090926
</p ><p className="about-text">
Thank you for choosing KuchBhiOrder.com as your e-commerce platform provider. We look forward to supporting your business journey!


</p>
</section>

</div>   

                </article>
            </main>

           












{/*footer section*/}
           
{/*footer section*/}


           
        </div>
    )
}

export default Return