import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import seo from "./assets/seo.jpg";
import UserFriendly from "./assets/UserFriendly.png";
import unlimitedProduct from "./assets/unlimitedproductcategories.png";
import storepickup from "./assets/storepickup.jpg";
import securePayment from "./assets/securepayment.png";
import giftcard from "./assets/giftcard.png";
import inventoryManagment from "./assets/inventorymanagement.jpg";
import liveNotification from "./assets/livenotification.jpg";
import service from "./assets/247service.jpg";
import responsive from "./assets/responsive.jpg";
import dashboard from "./assets/dashboard.jpg";

const FeaturesSection = () => {
  const API_URL = "https://kborder.onrender.com"; // Base URL for API requests

  const [, setCategorizedImages] = useState({});
  const [, setLoading] = useState(true);
  const [, setErrorMessage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-images`);
        const images = response.data.data;
        categorizeImagesBySection(images);
      } catch (error) {
        console.error("Error fetching images:", error);
        setErrorMessage("Failed to load images. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const categorizeImagesBySection = (images) => {
    const categorized = images.reduce((acc, image) => {
      const { category } = image;
      if (!acc[category]) acc[category] = [];
      acc[category].push(image);
      return acc;
    }, {});

    setCategorizedImages(categorized);
  };

  return (
    <section className="section blog" id="features">
      <div className="container">
        <h2 className="h2 section-title underline">
          Features of Kuch Bhi Order
        </h2>

        <ul className="blog-list">
          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={UserFriendly}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="User friendly Interface"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">User-Friendly Interface</a>
                </h3>

                <p className="text">
                  Our platform is designed with simplicity in mind, allowing
                  users of all technical backgrounds to navigate and manage
                  their stores effortlessly. The intuitive dashboard makes it
                  easy to update products, track sales, and view analytics at a
                  glance.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={unlimitedProduct}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Unlimited Products and Categories"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Unlimited Products and Categories</a>
                </h3>

                <p className="text">
                  Scale your business without limitations. Kuch Bhi Order allows
                  you to add an unlimited number of products and create
                  customized categories to help your customers find what they’re
                  looking for quickly and easily.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={securePayment}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Secure Payment Gateway"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Secure Payment Gateway</a>
                </h3>

                <p className="text">
                  Provide peace of mind to your customers with a secure payment
                  gateway that supports multiple payment options. Ensure safe
                  transactions with advanced encryption, making online shopping
                  a secure experience.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={inventoryManagment}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Inventory Management"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Inventory Management</a>
                </h3>

                <p className="text">
                  Efficiently track your stock levels and manage inventory with
                  our powerful tools. Receive alerts for low stock, easily
                  update product details, and avoid overselling or stockouts,
                  all from a centralized dashboard.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          {/*next slider*/}
          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={giftcard}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Gift Card as Discounts"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Gift Cards as Discounts</a>
                </h3>

                <p className="text">
                  Increase customer loyalty by offering redeemable gift cards as
                  discounts for future purchases. This feature encourages repeat
                  business and provides customers with an incentive to return to
                  your store.{" "}
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={storepickup}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Store Pickup facility"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Store Pickup Facility</a>
                </h3>

                <p className="text">
                  Enhance customer convenience with the option for store pickup.
                  Customers can select this option during checkout, allowing
                  them to collect their orders directly from your physical
                  location, saving on shipping costs.
                </p>
                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={storepickup}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Seamless Connectivity with Customers"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Seamless Connectivity with Customers</a>
                </h3>

                <p className="text">
                  Foster strong relationships with your customers through
                  integrated communication tools. Utilize email marketing, live
                  chat, and personalized messaging to keep customers informed
                  and engaged throughout their shopping journey.
                </p>
                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={liveNotification}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Live Notification"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Live Notifications</a>
                </h3>

                <p className="text">
                  Keep your customers engaged with live notifications for new
                  product launches, special promotions, and important updates.
                  This feature helps drive sales and enhances customer
                  interaction, keeping your audience informed and excited.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          {/*next third slider*/}
          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={seo}
                    width="100%"
                    // height="350"
                    loading="lazy"
                    alt="SEO Friendly"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">SEO-Friendly</a>
                </h3>

                <p className="text">
                  Optimize your online store for search engines with built-in
                  SEO tools. Improve your site’s visibility and attract more
                  customers by optimizing product descriptions, meta tags, and
                  URLs for better search engine rankings.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={responsive}
                    width="100%"
                    loading="lazy"
                    alt="Donec feugiat mollis nisi in dignissim. Morbi sollicitudin quis."
                    className="Mobile Responsive"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Mobile Responsive</a>
                </h3>

                <p className="text">
                  Ensure your store provides an excellent shopping experience on
                  all devices. Our mobile-responsive design adjusts seamlessly
                  to smartphones and tablets, allowing customers to shop
                  anywhere, anytime.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={dashboard}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="Analytics and Reporting"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">Analytics and Reporting</a>
                </h3>

                <p className="text">
                  Gain valuable insights into your store’s performance with
                  comprehensive analytics and reporting tools. Track sales
                  trends, customer behavior, and inventory levels to make
                  informed decisions that drive growth.
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>

          <li>
            <div className="blog-card">
              <figure className="banner">
                <a href="#">
                  <img
                    src={service}
                    width="750"
                    height="350"
                    loading="lazy"
                    alt="24/7 Customer Support"
                    className="img-cover"
                  />
                </a>
              </figure>

              <div className="content">
                <h3 className="h3 title">
                  <a href="#">24/7 Customer Support</a>
                </h3>

                <p className="text">
                  Our dedicated support team is available around the clock to
                  assist you with any queries or issues. Whether you need
                  technical support or guidance on using our features, we’re
                  here to help you succeed.{" "}
                </p>

                <div className="meta"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default FeaturesSection;
