import React, { useState, useEffect } from "react";
import addproduct from './assets/steps/addproducts.png'
import domain from './assets/steps/domain.png'
import engage from './assets/steps/engage.png'
import launch from './assets/steps/launch.png'
import payment from './assets/steps/payment.png'
import setupstore from './assets/steps/setupstore.png'
import signup from './assets/steps/signup.png'

const StepsSlider = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  // const words = ["CREATE", "CUSTOMIZE", "LAUNCH"];
  const baseText = "Your Online Store with KuchBhiOrder.com";

  const items = [
    { id: 1, src: signup, title: "Sign Up", text: "Create your account on KuchBhiOrder.com by filling out a simple registration form." },
    { id: 2, src: domain, title: "Choose Your Domain", text: "Select a unique domain name that represents your brand and purchase it through our platform." },
    { id: 3, src: setupstore, title: "Set Up Your Store", text: "Use our intuitive interface to customize your store’s design, layout, and branding to reflect your vision." },
    { id: 4, src: addproduct, title: "Add Products", text: "Upload your products, including images, descriptions, prices, and inventory details. Organize them into categories for easy navigation." },
    { id: 5, src: payment, title: "Configure Payment Options", text: "Choose from various secure payment methods to provide flexibility for your customers during checkout." },
    { id: 6, src: launch, title: "Launch Your Store", text: "Once everything is set up, go live and start promoting your store to attract customers." },
    { id: 7, src: engage, title: "Engage with Customers", text: "Utilize live notifications to keep your customers informed about new products, sales, and updates." },
  ];

  return (
    <div className="container">
      <h2 className="h2 section-title underline">Start Your Online Store </h2>
      <h4 className="stepssubheading">With These Simple Steps:</h4>
      <div className="grid-container">
  {items.map((item) => (
    <div
      key={item.id}
      className={`grid-item ${item.id === 7? "move-to-second-column" : ""}`}
    >
      <img src={item.src} alt={item.title} className="grid-image" />
      <div className="grid-content">
        <div className="grid-number">Step {item.id}</div> {/* Add this for the number */}
        <h3>{item.title}</h3>
        <p>{item.text}</p>
      </div>
    </div>
  ))}
</div>

    </div>
  );
};

export default StepsSlider;