
import React from 'react'
import './PrivacyPolicy.css'
import { Link,useNavigate } from 'react-router-dom'
import Footer from './Footer';

function PrivacyPolicy() {
    const currentYear = new Date().getFullYear()
    const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');  // Navigate to the home page (root URL)
  };
    return (
        <div>
            {/*header section*/}
            <main>
                <article>
                    {/* #HERO */}


                    <section className="hero" id="home">
                        <div className="container">
                            <div className="hero-content">
                                {/* <p  className="hero-subtitle">We Are Product Designer From UK</p > */}
                                <p  className="hero-subtitle">How Kuchbhiorder handles your data</p >
                                {/* <h2 className="h2 hero-title">We Design Interfaces That Users Love</h2> */}
                                <h2 className="h2 hero-title">Privacy Policy  </h2>

                                {/* <p  className="hero-text">
                                        Morbi sed lacus nec risus finibus feugiat et fermentum nibh.
                                        Pellentesque vitae ante at elit fringilla ac at purus.
                                    </p > */}
      <button className="btn" onClick={goToHomePage}>Back To Home</button>
      </div>

                            <figure className="hero-banner">
                                <img
                                    src="https://raw.githubusercontent.com/codewithsadee/desinic/master/assets/images/hero-banner.png"
                                    width="694"
                                    height="529"
                                    loading="lazy"
                                    alt="hero-banner"
                                    className="w-100 banner-animation"
                                />
                            </figure>
                        </div>
                    </section>

               
<div>
    <section className='section privacy'>

<h2>Introduction </h2>
<p className="about-text">In our mission to make e-commerce better for everyone at Kuchbhiorder, we collect and use information about you, our </p >
<ul>
    <li>
Merchants using BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED to power your business 
</li><li>
Customers who shop at a Kuchbhiorder-powered business 
</li><li>
Partners who develop apps for merchants to use, build stores on behalf of merchants, refer potential entrepreneurs to Kuchbhiorder, or otherwise help merchants operate or improve their Kuchbhiorder-powered business 
</li><li>
Visitors to Kuchbhiorder website, or anyone contacting Kuchbhiorder support 
</li>
</ul>
<p className="about-text">This Privacy Policy will help you better understand how we collect, use, and share your personal information. If we change our privacy practices, we may update this privacy policy. If any changes are significant, we will let you know (for example, through the Kuchbhiorder admin or by email).</p >
<h2>Our values </h2>
<p className="about-text">Trust is the foundation of the Kuchbhiorder platform and includes trusting us to do the right thing with your information. Three main values guide us as we develop our products and services. These values should help you better understand how we think about your information and privacy. </p >
<h2>Your information belongs to you </h2>
<p className="about-text">We carefully analyze what types of information we need to provide our services, and we try to limit the information we collect to only what we really need. Where possible, we delete or anonymize this information when we no longer need it. When building and improving our products, our engineers work closely with our privacy and security teams to build with privacy in mind. In all of this work, our guiding principle is that your information belongs to you, and we aim to only use your information to your benefit. </p >
<h2> We protect your information from others </h2>
<p className="about-text">If a third party requests your personal information, we will refuse to share it unless you give us permission or we are legally required. When we are legally required to share your personal information, we will tell you in advance, unless we are legally forbidden. </p >
<h2>We help merchants and partners meet their privacy obligations </h2>
<p className="about-text">Many of the merchants and partners using Kuchbhiorder do not have the benefit of a dedicated privacy team, and it is important to us to help them meet their privacy obligations. To do this, we try to build our products and services so they can easily be used in a privacy-friendly way. We also provide detailed FAQs and documentation covering the most important privacy topics, and respond to privacy-related questions we receive. </p >
<h2>Why we process your information </h2>
<p className="about-text">
We generally process your information when we need to do so to fulfill a contractual obligation (for example, to process your subscription payments to use the Kuchbhiorder platform), or where we or someone we work with needs to use your personal information for a reason related to their business (for example, to provide you with a service). The use of the information collected include: 

• preventing risk and fraud 

• answering questions or providing other types of support 

• helping merchants find and use apps through our app store 

• providing and improving our products and services 

• Providing reporting and analytics 

• testing out features or additional services 

• assisting with marketing, advertising, or other communications 

We only process personal information for these “legitimate interests” after considering the potential risks to your privacy and balancing any risks with certain measures—for example, by providing clear transparency into our privacy practices, offering you control over your personal information where appropriate, limiting the information we keep, limiting what we do with your information, who we send your information to, how long we keep your information, or the technical measures we use to protect your information. 

We may also process your personal information where you have provided your consent. In particular, where we cannot rely on an alternative legal basis for processing, where you direct us to transfer information to a third party, where we receive your data from a third party is sourced and it already comes with consent or where we are required by law to ask for your consent (including in the context of some of our sales and marketing activities). At any time, you have a right to withdraw your consent by changing your communication choices, opting out from our communications or by contacting us. 

Depending on whether you are a merchant, customer, partner, user or visitor, please refer to our supplemental privacy policies, as relevant, to understand our purposes for processing, categories of recipients and legal basis for processing for each type of personal data. 
</p >

<h2>Your rights over your information </h2>
<p className="about-text">
We believe you should be able to access and control your personal information no matter where you live. Depending on how you use Kuchbhiorder, you may have the right to request access to, correct, amend, delete, port to another service provider, restrict, or object to certain uses of your personal information. We will not charge you more or provide you with a different level of service if you exercise any of these rights. Please note that a number of these rights apply only in certain circumstances, and all of these rights may be limited by law. 
</p >
<p className="about-text">
If you buy something from or otherwise provide your information to a Kuchbhiorder -powered store and wish to exercise these rights over information about your purchase or interaction, you need to directly contact the merchant you interacted with. We are a processor and process information on their behalf. We will of course help our merchants to fulfill these requests to the extent required by law, such as by giving them the tools to do so and by answering their questions. 

If you are a merchant, partner, user, Kuchbhiorder employee, website visitor or other individual that Kuchbhiorder has a direct relationship with, please submit your data subject request through our online portal. Please note that if you send us a request relating to your personal information, we have to make sure that it is you before we can respond. In order to do so, we may use a third party to collect and verify identification documents. 

If you are not happy with our response to a request, you can contact us to resolve the issue.  

Finally, because there is no common understanding about what a “Do Not Track” signal is supposed to mean, we don’t respond to those signals in any particular way. 
</p >

<h2>Where we send your information </h2>
<p className="about-text">
We are a Indian company, but we work with and process data about individuals across the world. To operate our business, we may send your personal information outside of your state, province, or country. This data may be subject to the laws of the countries where we send it. We take steps to protect your information when we send your information across borders. 

Depending on whether you are a merchant, customer, partner, user or visitor, please refer to our supplemental privacy policies, as relevant. 
</p >
<h2>How long do we retain your information </h2>
<p className="about-text">
We will retain your personal data only for as long as necessary to fulfill the purposes for which we have collected it. To determine the appropriate retention period, we consider the amount, nature and sensitivity of your personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means and the applicable legal requirements. We will also retain and use your personal information to the extent necessary to comply with our legal obligations, resolve disputes and enforce our policies. If you stop using our services or if you delete your account with us, we will delete your information or store your information in an aggregated and anonymized format. 
</p ><p className="about-text">
Depending on whether you are a merchant, customer, partner, user or visitor, please refer to our supplemental privacy policies, as relevant, for further details on the retention of your personal information. 
</p >
<h2>Our use of Machine Learning </h2>
<p className="about-text">One of the ways in which we are able to help merchants using Kuchbhiorder is by using techniques like “machine learning” to help us improve our services. When we use machine learning, we either: (1) still have a human being involved in the process (and so are not fully automated); or (2) use machine learning in ways that don’t have legal or similarly significant effects (for example, reordering how apps might appear when you visit the app store). </p >

<h2>How we protect your information </h2>
<p className="about-text">Our teams work tirelessly to protect your information and to ensure the security and integrity of our platform. We also have independent auditors assess the security of our data storage and systems that process financial information. However, we all know that no method of transmission over the Internet, and method of electronic storage, can be 100% secure. This means we cannot guarantee the absolute security of your personal information. You can find more information about our security measures at /in/security. </p >

<h2>How we use “cookies” and other tracking technologies </h2>
<p className="about-text">We use cookies and similar tracking technologies on our website and when providing our services. For more information about how we use these technologies, including a list of other companies that place cookies on our sites, a list of cookies that we place when we power a merchant’s store, and an explanation of how you can opt out of certain types of cookies, please see our Cookie Policy. </p >

<h2>How you can reach us </h2>
<p className="about-text">If you would like to ask about, make a request relating to, or complain about how we process your personal information, please contact Kuchbhiorder Support, or mail us at one of the addresses below. If you would like to submit a legally binding request to demand someone else’s personal information (for example, if you have a subpoena or court order), please review our Guidelines for Legal Requests. 
</p ><p className="about-text">
If you have questions about how a merchant or store processes your personal information, you should contact the merchant or visit their privacy policy. </p >

<h2>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED., </h2>
<p >
First floor, Above CSB Bank, Opp.  
</p ><p >
Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri,  
</p ><p >
Pune - Maharashtra, India-411018 
</p >

<h2>Why we collect and share your Personal Information</h2>
<p className="about-text">We use and share your personal information for the purposes set out in the Kuchbhiorder Privacy Policies. For categories of sensitive personal information that we collect, we only use or disclose such information either with your specific consent when required, or as otherwise permitted by law. </p >


<h2>Sources of Personal Information </h2>
<p className="about-text">To make commerce better for everyone at Kuchbhiorder, we collect and use personal information provided by: </p >
<p className="about-text">• You: We collect the information you provide when you use our platform, including when you sign up for Kuchbhiorder as a merchant, visit a Kuchbhiorder -powered store, fill in order information, visit one of Kuchbhiorder websites or contact Kuchbhiorder support. We collect account and payment information you provide to us (including information about your business if you are a merchant), Kuchbhiorder stores or items you save to favorites, purchases you make, reviews you post, and how you otherwise interact or communicate with stores or other users on our apps or services. We also collect information about how you browse through our apps and sites, including search terms you may enter. </p >
<p className="about-text">• Your device(s): We collect information from and about the devices you use, including computers, phones, and other web-connected devices you use to access our apps or services, and we combine this information across different devices you use.</p >
<p className="about-text">• Third parties: We receive information from partners who help us provide you with our services including the following: </p >
<p className="about-text">
• Email providers. If you use the Shop App and you connect your third party inboxes, such as Gmail or Outlook (according to their terms and policies and as permitted by applicable law), we receive information to identify shopping-related emails and display within Shop information about specific orders you have made, stores you have engaged with in the past, and other related information. 
</p ><p className="about-text">
• Service Providers. We receive information from our service providers, who help us provide services to our merchants, like reviewing accounts for fraud or other concerns. 
</p ><p className="about-text">
• Marketplaces. If you use the Shop App, we receive information about purchases you have made from other marketplaces or platforms, such as Amazon, that you choose to connect through Shop. This information helps us to provide and improve Shop, to personalize your experience using our apps and services, and to determine if you are eligible for specific offers or payment methods. 
</p ><p className="about-text">
• Subprocessors. We work with third party subprocessors for cloud hosting, content delivery, data analysis, internal logging, fulfillment services and email transmission, among others, to provide you with our services. For more information, see Kuchbhiorder subprocessors. 
</p ><p className="about-text">
• Analytics and cookie providers. We receive information through our use of cookies, social plugins (such as the Facebook “like” button), pixels and tags for business purposes, such as providing information to help measure how users interact with our website content. For more information about how we use these technologies, see our Cookie Policy. 
</p >

<h2>How long we keep your information </h2>
<p className="about-text">
Because we need your personal information to provide Kuchbhiorder services, we generally keep your personal information, including sensitive personal information, while you use Kuchbhiorder products or services or until you tell us to delete your information. We may also keep personal information to comply with legal obligations or protect our or other’s interests. 
</p ><p className="about-text">
If you are a merchant operating a Kuchbhiorder -powered store, and you close the store, stop paying your subscription fees, or we terminate your account, we retain store information for two years before we begin the deletion process. 
</p ><p className="about-text">
When you visit or make a purchase from a merchant’s Kuchbhiorder -powered store, we act as a service provider or processor for the merchant, and the merchant, not Kuchbhiorder, decides how long your information is retained. 
</p >

<h2>Your rights over your information </h2>
<p className="about-text">
Depending on where you live, how you use Kuchbhiorder, and subject to certain exceptions, you may have some or all of the following rights: 
</p ><p className="about-text">
• Right to Know: The right to request that we disclose to you the personal information we collect, use, or disclose about you, and information about our data practices. 
</p ><p className="about-text">
• Right to Request Correction: The right to request that we correct inaccurate personal information that we maintain about you. 
</p ><p className="about-text">
• Right to Request Deletion: The right to request that we delete personal information that we have collected about you. 
</p ><p className="about-text">
To exercise your rights, including the “right to know” and “right to delete,” please submit a request through our online portal.  
</p ><p className="about-text">
If you have visited or made a purchase from a merchant’s Kuchbhiorder-powered store, please contact the specific merchant directly. If you make a request to us, we will forward your request to the relevant merchant. 
</p >
<p className="about-text">
Please note that to protect your information and the integrity of our products and services, we may need to verify your identity before processing your request. In some cases we may need to collect additional information to verify your identity, such as your email address or a government issued ID. 
</p ><p className="about-text">
If you use an authorized agent to submit a request, we may need to collect additional information, such as a government issued ID, to verify your identity before processing your request to protect your information. 
</p ><p className="about-text">
For information on the CCPA requests we have received, please see here. In certain states, you may have the right to appeal our decision regarding a request related to these rights. If you wish to appeal a decision, please contact Kuchbhiorder Support. 
</p ><p className="about-text">
We will not discriminate against you for exercising any of these rights. 
</p >

<h2>How you can reach us </h2>
<p className="about-text">If you would like to ask about or have concerns about how we process your personal information, please contact Kuchbhiorder Support. If you want to make a request relating to your personal information, please contact us using the methods set out in the section immediately above. </p >
<h2>BHALCHANDRAYA INNOVATIVE SERVICES PRIVATE LIMITED., </h2>
<p className="about-text"> First floor, Above CSB Bank, Opp.  
</p ><p className="about-text">
Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri,  
</p ><p className="about-text">
Pune - Maharashtra, India-411018 
</p ><p className="about-text">
Email: contact@kuchbhiorder.com 
</p ><p className="about-text">
Phone: +91 9423529427 / 9272090926 </p >
<p ></p >
<p ></p >
<p ></p >
<p ></p >

</section>

</div>   

                </article>
            </main>

           












           
        </div>
    )
}

export default PrivacyPolicy