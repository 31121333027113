import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { IoLogoYoutube } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa6";
import { useEffect, useState } from "react";
import axios from "axios";

function Footer({ currentYear }) {
  const [currentLogo, setCurrentLogo] = useState(""); // Default logo path or initial logo
  const API_URL = "https://kborder.onrender.com"; // Backend URL
  // Fetch the current logo when the component mounts
  useEffect(() => {
    const fetchCurrentLogo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/current-logo`);
        console.log("Fetched logo:", response.data.logo); // Log the logo path
        if (response.data.success && response.data.logo) {
          setCurrentLogo(response.data.logo); // Set fetched logo
        }
      } catch (error) {
        console.error("Error fetching current logo:", error);
      }
    };

    fetchCurrentLogo();
  }, []);
  return (
    <div>
      <div className="footer-top section">
        <div className="footer-main">
          <div className="footer-brand">
            <a href="#home">
              <img
                src={`${API_URL}${currentLogo}`} // Fallback to default logo
                alt="Kuch Bhi Order-Logo"
                style={{ width: "200px", height: "auto" }}
              />
            </a>

            <div className="footer-social-icons">
              <a
                href="https://www.facebook.com/share/18ML2vRDo9/"
                className="icon-link"
                aria-label="Facebook"
              >
                <FaFacebook size={30} className="social-icon facebook-icon" />
              </a>
              <a
                href="https://www.instagram.com/kuch_bhi_order"
                className="icon-link"
                aria-label="Instagram"
              >
                <RiInstagramFill
                  size={30}
                  className="social-icon instagram-icon"
                />
              </a>
              <a
                href="http://www.youtube.com/@KuchBhiOrder"
                className="icon-link"
                aria-label="YouTube"
              >
                <IoLogoYoutube size={30} className="social-icon youtube-icon" />
              </a>
            </div>
          </div>

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Our links</p>
            </li>

            <li>
              <a href="#home" className="footer-link">
                Home
              </a>
            </li>

            <li>
              <a href="#about" className="footer-link">
                About Us
              </a>
            </li>

            <li>
              <a href="#resources" className="footer-link">
                Resources
              </a>
            </li>

            <li>
              <a href="#Pricing" className="footer-link">
                Pricing
              </a>
            </li>

            <li>
              <a href="#features" className="footer-link">
                Features
              </a>
            </li>

            <li>
              <a href="#FAQ's" className="footer-link">
                FAQ's
              </a>
            </li>
          </ul>

          {/* <ul className="footer-list">
                            <li>
                                <p className="footer-list-title"></p>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>

                            <li>
                                <a href="#" className="footer-link"></a>
                            </li>
                        </ul> */}

          <ul className="footer-list">
            <li>
              <p className="footer-list-title">Other links</p>
            </li>

            <li>
              <Link to="/privacy" className="footer-link">
                Privacy Policy
              </Link>
            </li>

            <li>
              <Link to="/tandc" className="footer-link">
                Terms of Service
              </Link>
            </li>

            <li>
              <Link to="/refundpolicy" className="footer-link">
                Refund Policy
              </Link>
            </li>
          </ul>

          <ul className="footer-list info">
            <li>
              <p className="footer-list-title">Contact Us</p>
            </li>

            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="call"></ion-icon>
              </div>

              <div>
                <p className="footer-item-link">+91 9423529427</p>
                <p className="footer-item-link">+91 9272090926</p>
              </div>
            </li>

            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="mail"></ion-icon>
              </div>

              <div>
                <p className="footer-item-link">contact@kuchbhiorder.com</p>
                <p href="#" className="footer-item-link">
                  sales@kuchbhiorder.com
                </p>
              </div>
            </li>

            <li className="footer-item">
              <div className="footer-item-icon">
                <ion-icon name="location"></ion-icon>
              </div>

              <address className="footer-item-link">
                First floor, Above CSB Bank, Opp.Thyssenkrupp Industry, Near
                Ambedkar Chowk, Pimpri, Pune - Maharashtra.{" "}
              </address>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="copyright">
          {/* &copy; 2023 <a href="#" className="copyright-link">Ted Martins</a>. All
                            Right Reserved */}
          <small>
            Copyright © {currentYear}.Bhalchandraya Innovative Services Private
            Limited. All Rights Reserved.
          </small>
        </p>
      </div>
    </div>
  );
}

export default Footer;
