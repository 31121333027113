import './App.css';
import LandingPage from './LandingPage';
import PricingForm from './PricingForms';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import TandC from './TandC';
import Return from './Return';
import Footer from './Footer';
import ScrollToTop from './ScrollTop'; // import the component

function App() {
  const currentYear = new Date().getFullYear()
  return (
    <div>
     
    
     <BrowserRouter>
     {/* <LandingPage/> */}
     <ScrollToTop /> 
       <Routes>
      
        {/* <Route path='/register' element={ <PricingForm/>}/> */}
        <Route path="/" element={<LandingPage />} />
          {/* Route for PricingForm (Register Page) */}
          <Route path="/register" element={<PricingForm />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tandc" element={<TandC />} />
          <Route path="/refundpolicy" element={<Return />} />
       </Routes>
       <footer className="footer">
           <Footer currentYear={currentYear}/>
            </footer>
     </BrowserRouter>
    </div>
  );
}

export default App;
