import React, { useState } from "react";
// import seo from "./assets/seo.jpg";
// import UserFriendly from "./assets/UserFriendly.png";
// import unlimitedProduct from "./assets/unlimitedproductcategories.png";
// import storepickup from "./assets/storepickup.jpg";
// import securePayment from "./assets/securepayment.png";
// import socialmedia from "./assets/socialmedia.jpg";
// import giftcard from "./assets/giftcard.png";
// import logoecom from "./assets/logoecom.png";
// import inventoryManagment from "./assets/inventorymanagement.jpg";
// import liveNotification from "./assets/livenotification.jpg";
// import service from "./assets/247service.jpg";
// import responsive from "./assets/responsive.jpg";
// import dashboard from "./assets/dashboard.jpg";
import './Search.css'

const Search = ({ websiteData }) => {
  const [query, setQuery] = useState("");

  // Filter stores based on the query
  const filteredStores = websiteData.filter((data) =>
    data.name.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <div>
      <section className="section blog" id="store">
        <div className="container1">
          <h2 className="h2 section-title underline">
            Stores of Kuch Bhi Order
          </h2>
          <input
            type="text"
            placeholder="Search Store..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="searchBar"
          />
          <ul className="blog-list1">
            {filteredStores.length > 0 ? (
              filteredStores.map((data, index) => (
                <li>
                  <div className="blog-card1">
                    <figure className="banner-store">
                      <a href={data.link} target="_blank">
                        {data.image && (
                          <img
                            src={`https://kborder.onrender.com/uploads/${data.image}`}
                            alt={data.name}
                          />
                        )}
                      </a>
                    </figure>

                    <div className="content">
                      <h3 className="h3 title">
                        <a href={data.link} style={{color:'#551A8B'}}>{data.name}</a>
                      </h3>

                      {/* <p className="text">
                        Our platform is designed with simplicity in mind, allowing users of all technical backgrounds to navigate and manage their stores effortlessly. The intuitive dashboard makes it easy to update products, track sales, and view analytics at a glance.
                      </p> */}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div className="container">
                <p>No stores found.</p>
              </div>
            )}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Search;
