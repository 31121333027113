import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const HeroSection = () => {
  const BASE_TEXT = "Your Online Store with KuchBhiOrder.com";
  const API_URL = "https://kborder.onrender.com"; // Base URL for APIs

  const [categorizedImages, setCategorizedImages] = useState({});
  const words = ["CREATE", "CUSTOMIZE", "LAUNCH"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentLetterIndex, setCurrentLetterIndex] = useState(0);

  // Fetch images and categorize them
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/get-images`);
        const images = response.data.data;
        categorizeImagesBySection(images);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  const categorizeImagesBySection = (images) => {
    const categorized = images.reduce((acc, image) => {
      const { category } = image;
      if (!acc[category]) acc[category] = [];
      acc[category].push(image);
      return acc;
    }, {});

    setCategorizedImages(categorized);
  };

  // Animation Logic for Words
  useEffect(() => {
    const letterTimer = setInterval(() => {
      setCurrentLetterIndex((prev) => {
        const nextIndex = prev + 1;
        if (nextIndex > words[currentWordIndex].length) {
          clearInterval(letterTimer);
        }
        return nextIndex;
      });
    }, 200);

    const wordTimer = setInterval(() => {
      setCurrentWordIndex((prev) => (prev + 1) % words.length);
      setCurrentLetterIndex(0); // Reset letters when moving to the next word
    }, 3000);

    return () => {
      clearInterval(letterTimer);
      clearInterval(wordTimer);
    };
  }, [currentWordIndex]);

  return (
    <section className="hero" id="home">
      <div className="container">
        <div className="hero-content">
          <h2 className="hello-world">
            <span className="additional-text">
              {words[currentWordIndex].split("").map((letter, index) => (
                <span
                  key={index}
                  className="letter"
                  style={{
                    opacity: currentLetterIndex > index ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                    animation:
                      currentLetterIndex > index
                        ? "fadeIn 0.5s forwards"
                        : "none",
                    animationDelay: `${index * 0.1}s`,
                  }}
                >
                  {letter}
                </span>
              ))}
            </span>
            <span className="base-text"> {BASE_TEXT}</span>
          </h2>
          <button className="btn">Learn More</button>
        </div>

        <figure className="hero-banner">
          {categorizedImages["home"] && categorizedImages["home"].length > 0 ? (
            <div className="image-item">
              <img
                src={`${API_URL}/${
                  categorizedImages["home"][
                    categorizedImages["home"].length - 1
                  ].imageUrl
                }`} // Get the most recent image
                width="694"
                height="auto"
                loading="lazy"
                alt="hero-banner"
                className="w-100 banner-animation"
              />
            </div>
          ) : (
            <p>Loading...</p> // Fallback while loading
          )}
        </figure>
      </div>
    </section>
  );
};

export default HeroSection;
