import React, { useState } from "react";
import "./RequestQuote.css";

const RequestQuote = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    annualRevenue: "",
    contactNumber: "",
    country: "",
    businessType: "",
    products: "",
    otherBusinessType: "",
    otherProducts: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://kborder.onrender.com/api/quotes/request-quote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      alert(data.message);
    } catch (error) {
      console.error("Error submitting quote:", error);
    }
  };

  return (
    <div className="request-quote">
      <form onSubmit={handleSubmit} className="request-quote-form">
        <div className="form-group">
          <label className="form-label-unique">First Name</label>
          <input
            type="text"
            name="firstName"
            className="form-control-unique"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label-unique">Last Name</label>
          <input
            type="text"
            name="lastName"
            className="form-control-unique"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label-unique">Business Email Address</label>
          <input
            type="email"
            name="email"
            className="form-control-unique"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label-unique">Company Name</label>
          <input
            type="text"
            name="companyName"
            className="form-control-unique"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label-unique">Contact Number</label>
          <input
            type="text"
            name="contactNumber"
            className="form-control-unique"
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group dropdown-unique">
          <label className="form-label-unique">Business Type</label>
          <select
            name="businessType"
            className="form-select-unique"
            onChange={handleChange}
            required
          >
            <option value="">Select...</option>
            <option value="Retailer">Retailer</option>
            <option value="Wholesaler">Wholesaler</option>
            <option value="Manufacturer">Manufacturer</option>
            <option value="Distributor">Distributor</option>
            <option value="Service provider">Service provider</option>
            <option value="Other">Other</option>
          </select>
          {formData.businessType === "Other" && (
            <input
              type="text"
              name="otherBusinessType"
              className="form-control-unique mt-2"
              placeholder="Please specify..."
              onChange={handleChange}
            />
          )}
        </div>
        <div className="form-group dropdown-unique">
          <label className="form-label-unique">Products You Deal In</label>
          <select
            name="products"
            className="form-select-unique"
            onChange={handleChange}
            required
          >
            <option value="">Select...</option>
            <option value="Electronics">Electronics</option>
            <option value="Fashion & Apparel">Fashion & Apparel</option>
            <option value="Home & Kitchen">Home & Kitchen</option>
            <option value="Beauty & Personal Care">
              Beauty & Personal Care
            </option>
            <option value="Groceries & Food Items">
              Groceries & Food Items
            </option>
            <option value="Furniture">Furniture</option>
            <option value="Books & Stationery">Books & Stationery</option>
            <option value="Sports & Fitness">Sports & Fitness</option>
            <option value="Automotive">Automotive</option>
            <option value="Others">Others</option>
          </select>
          {formData.products === "Others" && (
            <input
              type="text"
              name="otherProducts"
              className="form-control-unique mt-2"
              placeholder="Please specify..."
              onChange={handleChange}
            />
          )}
        </div>
        <button type="submit" className="btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default RequestQuote;
